import React from "react";
import { useEffect } from "react";
import Header from "../components/Header/Header";
import Hero from "../components/Hero/Hero";
import Intro from "../components/Intro/Intro";
import Designation from "../components/Designation/Designation";
import Benefits from "../components/Benefits/Benefits";
import Projects from "../components/Projects/Projects";
import About from "../components/About/About";
import Faq from "../components/Faq/Faq";
import Request from "../components/Request/Request";
import Contact from "../components/Contact/Contact";
import Social from "../components/Social/Social";
import Footer from "../components/Footer/Footer";
import ScrollToTop from "../components/Scroll/ScrollToTop";
import Blockchain from "../components/Blockchain/Blockchain";
function Home() {
  useEffect(() => {
    document.title = "Tineth Pathirage - Certified MERN Expert";
  }, []);
  return (
    <>
      <Header />
      <Hero />
      <Intro />
      <Designation />
      <Benefits />
      <About />
      <Blockchain/>
      <Projects />
      <Faq />
      <Request />
      <Contact />
      <Social />
      <Footer />
      <ScrollToTop />
    </>
  );
}

export default Home;

import React from "react";
import { ContactWrapper } from "./IntroElements";

import ScrollAnimation from "react-animate-on-scroll";
function Intro() {
  return (
    <ContactWrapper>
      <div className="Container">
        <div className="line">
          <div class="intro-top-line"></div>
        </div>
        <ScrollAnimation animateIn="fadeIn">
          <section class="section">
            <div class="section-header section-header--intro">
              <h2 class="fluid-gradient-heading fluid-gradient-heading--hero cc-en">
                World-class full stack development for ambitious companies
                worldwide.
              </h2>
            </div>
          </section>
        </ScrollAnimation>
      </div>
    </ContactWrapper>
  );
}

export default Intro;

import styled from "@emotion/styled";

export const ContactWrapper = styled.div`
  margin: 2rem 0 0rem 0;
  background-color: #ffffff;

  .section-header {
    padding-top: 200px;
    padding-bottom: 200px;
    margin-bottom: 142px;
    margin-left: auto;
    margin-right: auto;
    max-width: 980px;
    text-align: left;
    width: 100%;
  }

  .section-header.section-header--intro {
    margin-bottom: 0;
    max-width: 1050px;
    position: relative;
  }

  h1 {
    font-size: 28px;
    text-align: center;
    font-weight: 600;
    letter-spacing: -0.045em;
    line-height: 1.1;
    opacity: 0.75;
  }

  h3 {
    font-size: 60px;
    text-align: center;
    font-weight: 600;
    letter-spacing: -0.045em;
    line-height: 1.1;
  }

  .fluid-gradient-heading {
    -webkit-text-fill-color: transparent;
    font-size: 92px;
    font-weight: 600;
    letter-spacing: -0.045em;
    line-height: 1.1;
    margin-top: 0;
    padding-bottom: 0px;
    padding-top: 0px;
  }

  .fluid-gradient-heading.fluid-gradient-heading--hero {
    line-height: 1.05;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }

  .fluid-gradient-heading.fluid-gradient-heading--hero.cc-en {
    font-size: 180px;
  }

  .fluid-gradient-heading {
    animation: intro-gradient 10s ease infinite both;
    background: linear-gradient(
      91.36deg,
      #eca658,
      #f391a6 13.02%,
      #e188c3 25.52%,
      #a58de3 37.5%,
      #56abec 49.48%,
      #737eb7 63.02%,
      #c8638c 72.92%,
      #dd5d57 84.38%,
      #df6c51 97.92%
    );
    -webkit-background-clip: text;
    background-clip: text;
    -moz-background-clip: text;
    background-size: 200% 200%;
  }

  @keyframes intro-gradient {
    0% {
      background-position: 0 4%;
    }
    50% {
      background-position: 100% 97%;
    }
    to {
      background-position: 0 4%;
    }
  }

  @media screen and (max-width: 991px) {
    margin: 0rem 0 0rem 0;
    .line {
      padding-top: 0px;
    }
    h1 {
      font-size: 20px;
    }
    h3 {
      font-size: 40px;
    }
    .section-header {
      padding-top: 100px;
      padding-bottom: 100px;
    }
    .fluid-gradient-heading.fluid-gradient-heading--hero.cc-en {
      font-size: 120px;
    }
  }

  @media (max-width: 768px) {
    margin: 0rem 0 0rem 0;
    .line {
      padding-top: 20px;
    }
    .section-header {
      padding-top: 100px;
      padding-bottom: 100px;
    }
    .fluid-gradient-heading {
      font-size: 48px;
    }

    .fluid-gradient-heading.fluid-gradient-heading--hero {
      font-size: 42px;
    }

    .fluid-gradient-heading.fluid-gradient-heading--hero.cc-de {
      font-size: 40px;
    }

    .fluid-gradient-heading.fluid-gradient-heading--hero.cc-en {
      font-size: 80px;
    }
  }

  @media (max-width: 480px) {
    margin: 0rem 0 0rem 0;
    h1 {
      font-size: 16px;
    }
    h3 {
      font-size: 32px;
    }
    .section-header {
      padding-top: 100px;
      padding-bottom: 100px;
    }
    .fluid-gradient-heading {
      font-size: 32px;
    }

    .fluid-gradient-heading.fluid-gradient-heading--hero {
      font-size: 28px;
    }

    .fluid-gradient-heading.fluid-gradient-heading--hero.cc-en {
      font-size: 64px;
    }
  }
`;

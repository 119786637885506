import styled from "@emotion/styled";

export const ContactWrapper = styled.div`
  margin: 0 0 0 0;
  background-color: #ffffff;

  .section {
    font-family: Inter, sans-serif;
    color: #1d1d1f;
    font-size: 16px;
    line-height: 1.2;
    margin: 0;
    min-height: 100%;
  }
  h1 {
    font-size: 38px;
    line-height: 44px;
  }
  h1,
  h2 {
    font-weight: 600;
    margin-bottom: 10px;
    margin-top: 20px;
  }
  h2 {
    font-size: 32px;
    line-height: 36px;
  }
  h3 {
    font-size: 24px;
    line-height: 30px;
    margin-top: 20px;
  }
  h3,
  h4 {
    font-weight: 600;
    margin-bottom: 10px;
  }
  h4 {
    font-size: 18px;
    line-height: 24px;
    margin-top: 10px;
  }

  .project-top-line {
    background-image: linear-gradient(
      90deg,
      hsla(0, 0%, 53%, 0),
      hsla(0, 0%, 53%, 0.3) 20%,
      hsla(0, 0%, 53%, 0.3) 80%,
      hsla(0, 0%, 53%, 0)
    );
    height: 1px;
    margin-left: auto;
    margin-right: auto;
    // max-width: 980px;
    width: 100%;
  }

  .section {
    padding-bottom: 140px;
    padding-top: 140px;
  }

  .section.section--manifest {
    padding-bottom: 160px;
    padding-top: 100px;
  }

  .section-heading {
    font-size: 92px;
    letter-spacing: -0.045em;
    line-height: 1.1;
    margin-bottom: 0;
    margin-top: 0;
    max-width: 890px;
  }

  .section-header {
    margin-bottom: 142px;
    margin-left: auto;
    margin-right: auto;
    max-width: 980px;
    text-align: left;
  }
  .fluid-gradient-heading {
    -webkit-text-fill-color: transparent;
    font-size: 92px;
    letter-spacing: -0.045em;
    line-height: 1.1;
    margin-top: 0;
    padding-bottom: 8px;
    padding-top: 8px;
  }
  .fluid-gradient-heading.fluid-gradient-heading--footer {
    margin-bottom: 24px;
  }
  .fluid-gradient-heading.fluid-gradient-heading--hero {
    line-height: 1.05;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }
  .fluid-gradient-heading.fluid-gradient-heading--hero.cc-de {
    font-size: 87px;
  }
  .fluid-gradient-heading.fluid-gradient-heading--hero.cc-en {
    font-size: 103px;
  }
  .section-heading-eyebrow {
    font-size: 28px;
    letter-spacing: -0.04em;
    line-height: 1.2;
    margin-bottom: 16px;
    margin-top: 0;
    opacity: 0.75;
    padding-left: 0.1em;
  }

  @media screen and (max-width: 991px) {
    .hero-heading {
      font-size: 72px;
    }
    .fluid-gradient-heading.fluid-gradient-heading--footer {
      font-size: 64px;
    }
    .section {
      padding-bottom: 80px;
      padding-top: 80px;
    }
    .section.section--manifest {
      display: none;
    }
    .section-heading {
      font-size: 72px;
      max-width: 650px;
    }

    .section-header {
      margin-bottom: 80px;
    }
    .section-heading {
      font-size: 56px;
    }
    .section-heading-eyebrow {
      font-size: 24px;
      padding-left: 0.1em;
    }
  }
  @media screen and (max-width: 479px) {
    .section-heading {
      font-size: 52px;
    }
    .fluid-gradient-heading {
      font-size: 34px;
    }
    .fluid-gradient-heading.fluid-gradient-heading--footer {
      font-size: 10vw;
      margin-bottom: 10px;
    }
  }
  @media screen and (max-width: 767px) {
    .fluid-gradient-heading.fluid-gradient-heading--footer {
      font-size: 10vw;
    }
  }

  .fluid-gradient-heading {
    animation: intro-gradient 10s infinite both;
  }
  .fluid-gradient-heading {
    animation: intro-gradient 10s ease infinite both;
    background: linear-gradient(
      91.36deg,
      #eca658,
      #f391a6 13.02%,
      #e188c3 25.52%,
      #a58de3 37.5%,
      #56abec 49.48%,
      #737eb7 63.02%,
      #c8638c 72.92%,
      #dd5d57 84.38%,
      #df6c51 97.92%
    );
    -webkit-background-clip: text;
    background-clip: text;
    -moz-background-clip: text;
    background-size: 200% 200%;
  }
  @keyframes intro-gradient {
    0% {
      background-position: 0 4%;
    }
    50% {
      background-position: 100% 97%;
    }
    to {
      background-position: 0 4%;
    }
  }
  .w-checkbox-input,
  .w-radio-input {
    box-shadow: none !important;
  }
  input,
  textarea {
    -webkit-appearance: none;
  }
  @media only screen and (max-width: 359px) {
    .section-heading {
      font-size: 48px;
    }
  }

  .section:focus {
    outline: 0;
  }
`;

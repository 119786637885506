import React from "react";
import { ContactWrapper } from "./BlockchainElements";

import ScrollAnimation from "react-animate-on-scroll";
function Blockchain() {
  return (
    <ContactWrapper>
      <div className="Container">
        <ScrollAnimation animateIn="fadeIn">
          <section class="section">
            <div class="section-header section-header--intro">
              <h1>Futuristic & Decentralized</h1>
              <h2 class="fluid-gradient-heading fluid-gradient-heading--hero cc-en">
                Blockchain
              </h2>
              <h3>Development.</h3>
            </div>
          </section>
        </ScrollAnimation>
      </div>
    </ContactWrapper>
  );
}

export default Blockchain;

import React from "react";
import { ContactWrapper } from "./ProjectsElements";

import ScrollAnimation from "react-animate-on-scroll";
function Projects() {
  return (
    <ContactWrapper>
      <div className="Container">
        <div className="line">
          <div class="project-top-line"></div>
        </div>
        <ScrollAnimation animateIn="fadeIn">
          <section id="projects" class="section">
            <div class="section-header">
              <h2 class="section-heading-eyebrow">Projects</h2>
              <h3 class="section-heading">A small selection of my projects.</h3>
            </div>
            <div class="section-header">
              <h2 class="section-heading-eyebrow fluid-gradient-heading fluid-gradient-heading--footer">
                Be patient, something are on the way. Amazing projects will be
                landed here.
              </h2>
            </div>
          </section>
        </ScrollAnimation>
      </div>
    </ContactWrapper>
  );
}

export default Projects;
